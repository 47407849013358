import "./set-public-path";
const publicIp = require('public-ip');

export async function getIp(){
    var ip=await publicIp.v4();
    return ip
}
// Anything exported from this file is importable by other in-browser modules.
export function publicApiFunction(
    
) {
    return "hola"
}
